@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap');

.admin-navbar {
    background-color: #3c8dbc;
    z-index: 500;

    &.navbar-dark .navbar-nav .nav-link:focus, &.navbar-dark .navbar-nav .nav-link:hover, &.navbar-dark .navbar-nav .nav-link {
        color: rgba(255, 255, 255, 1);
    }
}

// ratios
.m-ratio {
    position: relative;
    width: 100%; /* desired width */
}

.m-ratio:before {
    content: "";
    display: block;
    padding-top: 100%; /* initial ratio of 1:1*/
}

.m-ratio-2x1:before {
    padding-top: 50%;
}

.m-ratio-1x2:before {
    padding-top: 200%;
}

.m-ratio-4x3:before {
    padding-top: 75%;
}

.m-ratio-16x9:before {
    padding-top: 56.25%;
}

.gallery-outer {
    padding: 2px;
}

.row.magnific-gallery {
    margin-left: -17px;
    margin-right: -17px;
}

.img-holder {
    /*-webkit-box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.75);
    box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.75);*/
    //-webkit-box-reflect: below 2px -webkit-gradient(linear, left top, left bottom, from(transparent), to(rgba(250, 250, 250, 0.2)));
}

/****************************
    App specific styles
 ****************************/
.header_main .brand-logo img {
    max-width: 285px;
}


.header_main .brand-logo {
    margin-top: -15px;
    margin-bottom: -15px;
}

@media (max-width: 767px) {
    .header_main .brand-logo {
        width: 50%;
    }
}

.category-list a{
    color: #8d8d8d;
}

.grey{
    color: #8d8d8d;
}

.rt-portfolio-box.element-one .rt-portfolio-box-item > .holder > .data, .rt-portfolio-box.element-one .rt-portfolio-box-item > .holder > .data ul.category-list {
    text-align: left !important;
}

